/* App.css */

body {
  background-color: #f8f9fa; /* Light background for a clean look */
}

.card {
  border-radius: 0.5rem; /* Rounded corners for cards */
}

.table th {
  background-color: #007bff; /* Bootstrap primary color */
  color: white; /* White text for header */
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2; /* Light grey for odd rows */
}

.pagination {
  margin-top: 20px; /* Adds spacing above the pagination */
}

.page-link {
  margin: 0 10px; /* Adds spacing between pagination buttons */
}

.table-responsive {
  max-height: 400px; /* Set a max height for the table */
  overflow-y: auto;  /* Enable vertical scrolling */
}
